"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var Container = (0, _styledBase["default"])("div", {
  target: "e86je280",
  label: "Container"
})(process.env.NODE_ENV === "production" ? {
  name: "1arve91",
  styles: "margin-left:auto;margin-right:auto;max-width:1020px;padding:0 20px;@media (min-width:768px){padding:0 40px;margin-top:30px;}@media (min-width:1024px){padding:0;}"
} : {
  name: "1arve91",
  styles: "margin-left:auto;margin-right:auto;max-width:1020px;padding:0 20px;@media (min-width:768px){padding:0 40px;margin-top:30px;}@media (min-width:1024px){padding:0;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2NvbnRhaW5lci5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFNEIiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvY29udGFpbmVyLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCI7XG5cbmNvbnN0IENvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIG1hcmdpbi1sZWZ0OiBhdXRvO1xuICBtYXJnaW4tcmlnaHQ6IGF1dG87XG4gIG1heC13aWR0aDogMTAyMHB4O1xuICBwYWRkaW5nOiAwIDIwcHg7XG5cbiAgQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XG4gICAgcGFkZGluZzogMCA0MHB4O1xuICAgIG1hcmdpbi10b3A6IDMwcHg7XG4gIH1cblxuICBAbWVkaWEgKG1pbi13aWR0aDogMTAyNHB4KSB7XG4gICAgcGFkZGluZzogMDtcbiAgfVxuYDtcblxuZXhwb3J0IGRlZmF1bHQgQ29udGFpbmVyO1xuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = Container;
exports["default"] = _default;