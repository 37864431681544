"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var DialogBox = (0, _styledBase["default"])("div", {
  target: "e1pufnt80",
  label: "DialogBox"
})(process.env.NODE_ENV === "production" ? {
  name: "1v9kdux",
  styles: "margin-left:0;margin-right:0;padding:35px 25px;border-radius:0;box-shadow:rgba(0,0,0,0.3) 0px 0px 3px 0px;position:relative;display:inline-block;width:100%;max-width:540px;@media (min-width:600px){padding:35px 40px;border-radius:6px;}"
} : {
  name: "1v9kdux",
  styles: "margin-left:0;margin-right:0;padding:35px 25px;border-radius:0;box-shadow:rgba(0,0,0,0.3) 0px 0px 3px 0px;position:relative;display:inline-block;width:100%;max-width:540px;@media (min-width:600px){padding:35px 40px;border-radius:6px;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2RpYWxvZy1ib3guanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRTRCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2RpYWxvZy1ib3guanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcblxuY29uc3QgRGlhbG9nQm94ID0gc3R5bGVkLmRpdmBcbiAgbWFyZ2luLWxlZnQ6IDA7XG4gIG1hcmdpbi1yaWdodDogMDtcbiAgcGFkZGluZzogMzVweCAyNXB4O1xuICBib3JkZXItcmFkaXVzOiAwO1xuICBib3gtc2hhZG93OiByZ2JhKDAsIDAsIDAsIDAuMykgMHB4IDBweCAzcHggMHB4O1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgd2lkdGg6IDEwMCU7XG4gIG1heC13aWR0aDogNTQwcHg7XG5cbiAgQG1lZGlhIChtaW4td2lkdGg6IDYwMHB4KSB7XG4gICAgcGFkZGluZzogMzVweCA0MHB4O1xuICAgIGJvcmRlci1yYWRpdXM6IDZweDtcbiAgfVxuYDtcblxuZXhwb3J0IGRlZmF1bHQgRGlhbG9nQm94O1xuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = DialogBox;
exports["default"] = _default;