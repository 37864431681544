"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scrollToTop = scrollToTop;
exports.scrollToElement = scrollToElement;

function scrollToTop() {
  var behavior = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "smooth";

  if (!window) {
    return;
  }

  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  } catch (error) {
    document.scrollTo(0, 0);
  }
}

function scrollToElement(element) {
  if (!window) {
    return;
  }

  var top = window.pageYOffset + element.getBoundingClientRect().top;

  try {
    window.scroll({
      top: top,
      left: 0,
      behavior: "smooth"
    });
  } catch (error) {
    document.scrollTo(0, top);
  }
}