"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var SubHeadlineIcon = (0, _styledBase["default"])("span", {
  target: "e1jrcsy00",
  label: "SubHeadlineIcon"
})(process.env.NODE_ENV === "production" ? {
  name: "1alqhcn",
  styles: "padding-right:10px;padding-top:3px;"
} : {
  name: "1alqhcn",
  styles: "padding-right:10px;padding-top:3px;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3N1Yi1oZWFkbGluZS1pY29uLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVtQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9zdWItaGVhZGxpbmUtaWNvbi5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xuXG5jb25zdCBTdWJIZWFkbGluZUljb24gPSBzdHlsZWQuc3BhbmBcbiAgcGFkZGluZy1yaWdodDogMTBweDtcbiAgcGFkZGluZy10b3A6IDNweDtcbmA7XG5cbmV4cG9ydCBkZWZhdWx0IFN1YkhlYWRsaW5lSWNvbjtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = SubHeadlineIcon;
exports["default"] = _default;