"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _passwordValidator = require("./password-validator");

Object.keys(_passwordValidator).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _passwordValidator[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _passwordValidator[key];
    }
  });
});