import styled from "@emotion/styled";
import React from "react";
import { Box } from "@rebass/grid/emotion";

const Root = styled.div`
  display: block;
  text-align: left;
  font-weight: 400;
`;

const Label = styled.label`
  text-align: left;
  display: block;
  color: #232f3e;

  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  @media (min-width: 768px) {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

const Input = styled.input`
  border-radius: 2px;
  width: 100%;
  outline: 0px;
  font-size: 13px;
  display: block;
  padding: 8px;
  color: ${props => (props.disabled ? "#545454" : "#232F3E")};
  background-color: ${props => (props.disabled ? "#E1E4EA" : "#FFFFFF")};
  background-image: none;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 3px;
  box-sizing: border-box;
`;

const LabelBox = styled(Box)`
  padding-bottom: 8px;
`;

export function DialogBoxTextInput({ label, name, placeholder, ...rest }) {
  return (
    <Root>
      <LabelBox>
        <Label>{label}</Label>
      </LabelBox>
      <Input
        name={name}
        id={name}
        tabindex="1"
        type="text"
        placeholder={placeholder}
        autoComplete="off"
        {...rest}
      />
    </Root>
  );
}
