"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var NavButton = (0, _styledBase["default"])("a", {
  target: "e19v8yrh0",
  label: "NavButton"
})(process.env.NODE_ENV === "production" ? {
  name: "1qnz445",
  styles: "max-width:180px;width:100%;height:42px;background-color:transparent;margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline;color:#50bcb7;border-radius:2px;border:1px solid #e1e1e1;display:flex;align-items:center;justify-content:center;background:#fff;color:#5f5e5a;transition:all 0.3s;&:hover{background-color:#f9f9f9;}cursor:pointer;"
} : {
  name: "1qnz445",
  styles: "max-width:180px;width:100%;height:42px;background-color:transparent;margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline;color:#50bcb7;border-radius:2px;border:1px solid #e1e1e1;display:flex;align-items:center;justify-content:center;background:#fff;color:#5f5e5a;transition:all 0.3s;&:hover{background-color:#f9f9f9;}cursor:pointer;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL25hdi1idXR0b24uanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRTBCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL25hdi1idXR0b24uanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcblxuY29uc3QgTmF2QnV0dG9uID0gc3R5bGVkLmFgXG4gIG1heC13aWR0aDogMTgwcHg7XG4gIHdpZHRoOiAxMDAlO1xuICBoZWlnaHQ6IDQycHg7XG4gIGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xuICBtYXJnaW46IDA7XG4gIHBhZGRpbmc6IDA7XG4gIGJvcmRlcjogMDtcbiAgZm9udC1zaXplOiAxMDAlO1xuICB2ZXJ0aWNhbC1hbGlnbjogYmFzZWxpbmU7XG4gIGNvbG9yOiAjNTBiY2I3O1xuICBib3JkZXItcmFkaXVzOiAycHg7XG4gIGJvcmRlcjogMXB4IHNvbGlkICNlMWUxZTE7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBiYWNrZ3JvdW5kOiAjZmZmO1xuICBjb2xvcjogIzVmNWU1YTtcbiAgdHJhbnNpdGlvbjogYWxsIDAuM3M7XG4gICY6aG92ZXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6ICNmOWY5Zjk7XG4gIH1cbiAgY3Vyc29yOiBwb2ludGVyO1xuYDtcblxuZXhwb3J0IGRlZmF1bHQgTmF2QnV0dG9uO1xuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = NavButton;
exports["default"] = _default;