"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eweDesignReact = void 0;
var subNav = {
  userMenu: "security-center-menu",
  stammdaten: "security-center-stammdaten",
  changePassword: "security-center-password",
  authConnect: "auth-connect-main",
  logout: "security-center-logout",
  contact: "security-center-contact"
};
var eweDesignReact = {
  subNav: subNav
};
exports.eweDesignReact = eweDesignReact;