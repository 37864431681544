"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var MainNavMenuItem = (0, _styledBase["default"])("a", {
  target: "e5r9spr0",
  label: "MainNavMenuItem"
})(process.env.NODE_ENV === "production" ? {
  name: "ar5o4c",
  styles: "font-weight:600;letter-spacing:0.1px;color:#fff;cursor:pointer;transition:all 0.3s;position:relative;flex-shrink:0;height:45px;display:flex;align-items:center;padding-left:20px;font-size:16px;text-decoration:none;box-sizing:border-box;font-family:\"Open Sans\",sans-serif;@media (min-width:768px){padding-left:0;}"
} : {
  name: "ar5o4c",
  styles: "font-weight:600;letter-spacing:0.1px;color:#fff;cursor:pointer;transition:all 0.3s;position:relative;flex-shrink:0;height:45px;display:flex;align-items:center;padding-left:20px;font-size:16px;text-decoration:none;box-sizing:border-box;font-family:\"Open Sans\",sans-serif;@media (min-width:768px){padding-left:0;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL3N1Yi1uYXYvbWVudS1pdGVtLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVnQyIsImZpbGUiOiIuLi8uLi8uLi9zcmMvY29tcG9uZW50cy9zdWItbmF2L21lbnUtaXRlbS5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xuXG5jb25zdCBNYWluTmF2TWVudUl0ZW0gPSBzdHlsZWQuYWBcbiAgZm9udC13ZWlnaHQ6IDYwMDtcbiAgbGV0dGVyLXNwYWNpbmc6IDAuMXB4O1xuICBjb2xvcjogI2ZmZjtcbiAgY3Vyc29yOiBwb2ludGVyO1xuICB0cmFuc2l0aW9uOiBhbGwgMC4zcztcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBmbGV4LXNocmluazogMDtcbiAgaGVpZ2h0OiA0NXB4O1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBwYWRkaW5nLWxlZnQ6IDIwcHg7XG4gIGZvbnQtc2l6ZTogMTZweDtcbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xuICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuICBmb250LWZhbWlseTogXCJPcGVuIFNhbnNcIiwgc2Fucy1zZXJpZjtcblxuICBAbWVkaWEgKG1pbi13aWR0aDogNzY4cHgpIHtcbiAgICBwYWRkaW5nLWxlZnQ6IDA7XG4gIH1cbmA7XG5cbmV4cG9ydCBkZWZhdWx0IE1haW5OYXZNZW51SXRlbTtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = MainNavMenuItem;
exports["default"] = _default;