"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ScrollToRef;

var _react = require("react");

var scrollToElement = function scrollToElement(element) {
  var top = window.pageYOffset + element.getBoundingClientRect().top;

  try {
    window.scroll({
      top: top,
      left: 0,
      behavior: "smooth"
    });
  } catch (error) {
    document.scrollTo(0, top);
  }
};

function ScrollToRef(_ref) {
  var scrollRef = _ref.scrollRef,
      active = _ref.active,
      _ref$behavior = _ref.behavior,
      behavior = _ref$behavior === void 0 ? "smooth" : _ref$behavior;
  (0, _react.useEffect)(function () {
    if (!window || !active) {
      return;
    }

    scrollToElement(scrollRef.current);
  }, [behavior, scrollRef, active]);
  return null;
}