"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.breakpoints = exports.palette = void 0;
var palette = {
  darkText: "#262626",
  lightText: "#ffffff",
  primaryBg: "#f7cd00",
  primaryBgHover: "#fff101",
  primaryIconBg: "#e4be03",
  primaryIconBgHover: "#f2e402",
  secondaryBg: "#4c85b1",
  secondaryBgHover: "#6ca5da",
  secondaryIconBg: "#3e6e92",
  secondaryIconBgHover: "#5a8bb9",
  tertiaryBg: "#008c7f",
  tertiaryBgHover: "#68c0ac",
  tertiaryIconBg: "#00796e",
  tertiaryIconBgHover: "#5aa897",
  lightGreyBg: "#B1ACAA",
  lightGreyBgHover: "#56524B",
  darkGreyBg: "#56524B"
};
exports.palette = palette;
var breakpoints = {
  tablet: "768px"
};
exports.breakpoints = breakpoints;
var _default = {
  buttons: {
    primary: {
      button: {
        color: palette.darkText,
        backgroundColor: palette.primaryBg
      },
      buttonHover: {
        backgroundColor: palette.primaryBgHover
      },
      icon: {
        backgroundColor: palette.primaryIconBg
      },
      iconHover: {
        backgroundColor: palette.primaryIconBgHover
      }
    },
    secondary: {
      button: {
        color: palette.lightText,
        backgroundColor: palette.secondaryBg
      },
      buttonHover: {
        backgroundColor: palette.secondaryBgHover
      },
      icon: {
        backgroundColor: palette.secondaryIconBg
      },
      iconHover: {
        backgroundColor: palette.secondaryIconBgHover
      }
    },
    tertiary: {
      button: {
        color: palette.lightText,
        backgroundColor: palette.tertiaryBg
      },
      buttonHover: {
        backgroundColor: palette.tertiaryBgHover
      },
      icon: {
        backgroundColor: palette.tertiaryIconBg
      },
      iconHover: {
        backgroundColor: palette.tertiaryIconBgHover
      }
    },
    white: {
      button: {
        color: "#262626",
        backgroundColor: "white",
        outline: "1px solid #E0E0E0"
      },
      buttonHover: {
        backgroundColor: "#E0E0E0"
      },
      icon: {
        backgroundColor: "#E0E0E0"
      },
      iconHover: {
        backgroundColor: "#E0E0E0"
      }
    },
    lightgrey: {
      button: {
        color: "#000000",
        backgroundColor: "#ebebeb"
      },
      buttonHover: {
        backgroundColor: "rgba(235, 235, 235, 0.7)"
      }
    },
    darkgrey: {
      button: {
        color: palette.lightText,
        backgroundColor: palette.darkGreyBg
      }
    }
  },
  breakpoints: breakpoints
};
exports["default"] = _default;