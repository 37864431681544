import styled from "@emotion/styled";
import { Box } from "@ewe-it/ewe-design-react";

export const InputContainer = styled(Box)`
  flex: 1 1 auto;
  padding-bottom: 16px;
  @media (min-width: 768px) {
    padding-bottom: 8px;
  }
`;
