"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var MainContainer = (0, _styledBase["default"])("div", {
  target: "e1o4587z0",
  label: "MainContainer"
})(process.env.NODE_ENV === "production" ? {
  name: "15gny5c",
  styles: "margin-left:auto;margin-right:auto;max-width:1020px;padding:40px 20px 0 20px;box-sizing:border-box;@media (min-width:768px){margin-top:30px;padding-top:80px;}"
} : {
  name: "15gny5c",
  styles: "margin-left:auto;margin-right:auto;max-width:1020px;padding:40px 20px 0 20px;box-sizing:border-box;@media (min-width:768px){margin-top:30px;padding-top:80px;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL21haW4tY29udGFpbmVyLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVnQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9tYWluLWNvbnRhaW5lci5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xuXG5jb25zdCBNYWluQ29udGFpbmVyID0gc3R5bGVkLmRpdmBcbiAgbWFyZ2luLWxlZnQ6IGF1dG87XG4gIG1hcmdpbi1yaWdodDogYXV0bztcbiAgbWF4LXdpZHRoOiAxMDIwcHg7XG4gIHBhZGRpbmc6IDQwcHggMjBweCAwIDIwcHg7XG4gIGJveC1zaXppbmc6IGJvcmRlci1ib3g7XG5cbiAgQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XG4gICAgbWFyZ2luLXRvcDogMzBweDtcbiAgICBwYWRkaW5nLXRvcDogODBweDtcbiAgfVxuYDtcblxuZXhwb3J0IGRlZmF1bHQgTWFpbkNvbnRhaW5lcjtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = MainContainer;
exports["default"] = _default;