"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCookieValue = void 0;

var getCookieValue = function getCookieValue(name) {
  var val = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return val ? val.pop() : "";
};

exports.getCookieValue = getCookieValue;