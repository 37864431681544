import styled from "@emotion/styled";

export const GridHost = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 1020px;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 20px;
  }
`;
