"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var ErrorContainer = (0, _styledBase["default"])("div", {
  target: "e1u8pwlc0",
  label: "ErrorContainer"
})(process.env.NODE_ENV === "production" ? {
  name: "jiwdn8",
  styles: "font-size:13px;color:red;font-weight:700;"
} : {
  name: "jiwdn8",
  styles: "font-size:13px;color:red;font-weight:700;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2Vycm9yLWNvbnRhaW5lci5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFaUMiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvZXJyb3ItY29udGFpbmVyLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCI7XG5cbmNvbnN0IEVycm9yQ29udGFpbmVyID0gc3R5bGVkLmRpdmBcbiAgZm9udC1zaXplOiAxM3B4O1xuICBjb2xvcjogcmVkO1xuICBmb250LXdlaWdodDogNzAwO1xuYDtcblxuZXhwb3J0IGRlZmF1bHQgRXJyb3JDb250YWluZXI7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = ErrorContainer;
exports["default"] = _default;