import styled from "@emotion/styled";
import { Box } from "@ewe-it/ewe-design-react";

export const ErrorBox = styled(Box)`
  padding: 0 0 20px 0;

  @media (min-width: 768px) {
    padding-top: 10px;
  }
`;
