"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUrlParameter = getUrlParameter;
exports.filterEweHostname = filterEweHostname;

function getUrlParameter(name) {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
} // Der Hostname wird auf eine Positvliste von Domainendungen geprüft, sowie im
// Entwicklungsmodus localhost, um nur einen Rücksprung zu erlaubten Domänen
// zuzulassen.


function filterEweHostname(urlString) {
  if (!urlString) return null;

  if (urlString === "https://d1ysw1saknomkm.cloudfront.net/energy") {
    return urlString;
  }

  var redirectUrl = new URL(decodeURIComponent(urlString));
  var hasEweHostname = redirectUrl.hostname === "ewe.de" || redirectUrl.hostname.endsWith(".ewe.de") || redirectUrl.hostname === "ewe.cloud" || redirectUrl.hostname.endsWith(".ewe.cloud") || redirectUrl.hostname === "ewetel.de" || redirectUrl.hostname.endsWith(".ewetel.de") || redirectUrl.protocol === "de.ewe.digital:" || process.env.REACT_APP_STAGE === "dev" && redirectUrl.hostname === "localhost" || // whitelist PIMCORE, die folgende redirect_uri auf kon verwenden:
  // http://es20-pimcore.test/login/redirect
  process.env.REACT_APP_STAGE === "stage" && redirectUrl.hostname.endsWith(".test");
  return hasEweHostname ? redirectUrl.toString() : null;
}