"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var SubHeadlineContent = (0, _styledBase["default"])("div", {
  target: "e1apufyd0",
  label: "SubHeadlineContent"
})(process.env.NODE_ENV === "production" ? {
  name: "l5gta0",
  styles: "font-size:20px;font-weight:400;font-style:normal;font-stretch:normal;line-height:normal;box-sizing:border-box;margin:0;padding:0;border:0;color:#262626;"
} : {
  name: "l5gta0",
  styles: "font-size:20px;font-weight:400;font-style:normal;font-stretch:normal;line-height:normal;box-sizing:border-box;margin:0;padding:0;border:0;color:#262626;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3N1Yi1oZWFkbGluZS1jb250ZW50LmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVxQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9zdWItaGVhZGxpbmUtY29udGVudC5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xuXG5jb25zdCBTdWJIZWFkbGluZUNvbnRlbnQgPSBzdHlsZWQuZGl2YFxuICBmb250LXNpemU6IDIwcHg7XG4gIGZvbnQtd2VpZ2h0OiA0MDA7XG4gIGZvbnQtc3R5bGU6IG5vcm1hbDtcbiAgZm9udC1zdHJldGNoOiBub3JtYWw7XG4gIGxpbmUtaGVpZ2h0OiBub3JtYWw7XG4gIGJveC1zaXppbmc6IGJvcmRlci1ib3g7XG4gIG1hcmdpbjogMDtcbiAgcGFkZGluZzogMDtcbiAgYm9yZGVyOiAwO1xuICBjb2xvcjogIzI2MjYyNjtcbmA7XG5cbmV4cG9ydCBkZWZhdWx0IFN1YkhlYWRsaW5lQ29udGVudDtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = SubHeadlineContent;
exports["default"] = _default;