import styled from "@emotion/styled";

export const Subline = styled.span`
  color: #333333;
  font-size: 13px;
  line-height: 20px;
  margin-top: 20px;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
