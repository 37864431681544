"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = void 0;

var addLeading0 = function addLeading0(number) {
  return number < 10 ? "0" + number : number.toString();
};

var formatDate = function formatDate(dateString) {
  var date = new Date(dateString);
  var day = addLeading0(date.getDate());
  var month = addLeading0(date.getMonth() + 1);
  var year = date.getFullYear();
  var hour = addLeading0(date.getHours());
  var minute = addLeading0(date.getMinutes());
  var second = addLeading0(date.getSeconds());
  return day + "." + month + "." + year + " " + hour + ":" + minute + ":" + second;
};

exports.formatDate = formatDate;