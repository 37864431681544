import React, { useState } from "react";
import styled from "@emotion/styled";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Amplify, { API, Auth } from "aws-amplify";
import { graphqlOperation } from "aws-amplify";
import {
  getUrlParameter,
  filterEweHostname,
  useAuth,
} from "@ewe-it/ewe-frontend-utils";
import * as mutations from "./graphql/mutations";
import {
  ThemeProvider,
  GlobalStyle,
  TopNav,
  SubNav,
  MobileMenu,
  Footer,
} from "@ewe-it/ewe-design-react";
import { AddAccountLink } from "./pages/add-account-link.js";
import { DeleteAccountLink } from "./pages/delete-account-link.js";
import { LinkedAccountsList } from "./pages/linked-accounts-list.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faPlusCircle,
  faPlusSquare,
  faLink,
  faUnlink,
} from "@fortawesome/free-solid-svg-icons";
import { stringify as stringifySearchParams } from "query-string";

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: #f0efee;
`;

const ContentWrapper = styled.div`
  padding-bottom: 60px;
  @media (min-width: 768px) {
    padding-bottom: 800px;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "",
});

const oauthRedirectUri = filterEweHostname(getUrlParameter("redirect_uri"));
const oauthLogoutUri = filterEweHostname(getUrlParameter("logout_uri"));
const oauthRedirectSearchParams = stringifySearchParams({
  // undefined values are not added, while "null" values lead to the key being
  // present without a value (e.g. "&foo"). That's why we replace the potential
  // "null" in oauthRedirectUri or oauthLogoutUri with "undefined" here.
  redirect_uri: oauthRedirectUri ? oauthRedirectUri : undefined,
  logout_uri: oauthLogoutUri ? oauthLogoutUri : undefined,
});

const oauth = {
  //domain: 'eweauth.auth.eu-central-1.amazoncognito.com',
  domain: process.env.REACT_APP_IDP_DOMAIN,
  scope: ["email", "openid"],
  redirectSignIn: `${process.env.REACT_APP_REDIRECT_SIGN_IN}?${oauthRedirectSearchParams}`,
  redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
  responseType: "token",
};

Auth.configure({ oauth });
library.add(faTrash, faPlusCircle, faPlusSquare, faLink, faUnlink);

function App() {
  return window.location.replace(
    process.env.REACT_APP_CSS_PORTAL + "/energie/contract-connect",
  );

  /* eslint-disable react-hooks/rules-of-hooks */
  const { authState, authData } = useAuth({ Auth, stringifySearchParams });
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [accountListHasEntries, setAccountListHasEntries] = useState(false);

  const redirectUri = filterEweHostname(getUrlParameter("redirect_uri"));
  const logoutUri = filterEweHostname(getUrlParameter("logout_uri"));
  const cssLink = redirectUri || process.env.REACT_APP_CSS_PORTAL;
  const logoutLink = logoutUri || process.env.REACT_APP_CSS_PORTAL;
  const redirectSearchParams = stringifySearchParams({
    // undefined values are not added, while "null" values lead to the key being
    // present without a value (e.g. "&foo"). That's why we replace the potential
    // "null" in redirectUri or logoutUri with "undefined" here.
    redirect_uri: redirectUri ? redirectUri : undefined,
    logout_uri: logoutUri ? logoutUri : undefined,
  });

  async function onAddAccountLink(input) {
    const kundennummer = input.input.kundennummer.trim().replace(/ /g, "");
    const vertragsnummer = input.input.vertragsnummer.trim().replace(/ /g, "");
    if (kundennummer.startsWith("2")) {
      const newLink = await API.graphql(
        graphqlOperation(mutations.createKundePowercloudZuordnung, {
          input: {
            kundennummer,
            vertragsnummer,
          },
        }),
      );
      return newLink.kundennummer;
    } else {
      const newLink = await API.graphql(
        graphqlOperation(mutations.createKundeZuordnung, {
          input: {
            kundennummer,
            vertragsnummer,
          },
        }),
      );
      return newLink.kundennummer;
    }
  }

  async function onDeleteAccountLinkPowercloud(kundennummer) {
    await API.graphql(
      graphqlOperation(mutations.deleteKundePowercloudZuordnung, {
        kundennummer,
      }),
    );
  }

  async function onDeleteAccountLinkEasyplus(kundennummer) {
    await API.graphql(
      graphqlOperation(mutations.deleteKundeEasyplusZuordnung, {
        kundennummer,
      }),
    );
  }

  if (authState !== "signedIn") {
    return null;
  }

  return (
    <ThemeProvider>
      <GlobalStyle />
      <PageContainer>
        <ContentWrapper>
          <TopNav onBurgerClick={() => setMobileMenuIsOpen(true)} />
          <SubNav
            showMenu={accountListHasEntries}
            username={authData.username}
            cssUri={cssLink}
            changePasswordUri={
              process.env.REACT_APP_URL_CHANGEPASSWORD +
              `?${stringifySearchParams({
                // Die redirect_uri enthält immer den Link auf das CSS Portal,
                // also entweder das CSS1.0 oder das CSS2.0.
                redirect_uri: redirectUri || undefined,
                // Da der Benutzer aber auch vom Contract Connect ins Security
                // Center gehen kann, müssen wir dem Security Center sowohl
                // mitteilen aus welchem CSS Portal der User ursprünglich kam (
                // über die redirect_uri), als auch wohin der User nach
                // Abschluss seiner Aktion im Security Center zurück soll (
                // über die back_uri). Für den Rücksprung wird der gesamte Pfad
                // mitgegeben, so dass auch das Ursprüngliche CSS Portal erhalten
                // bleibt.
                //
                // Der Benutzer soll nach dem Ändern des Passworts immer auf die
                // verweisende Seite zurückgeleitet werden. Die verweisende Seite
                // kann aber selbst bereits eine verweisende Seite im URL-Parameter
                // (als ?redirect_uri=foo) enthalten, der nicht verloren gehen darf.
                //
                // Aus Sicht eines Benutzers der aus dem CSS 2.0 ins Contract-Connect
                // kommt und "Passswort ändern" auswählt, sehen die Breadcrumbs
                // also so aus: CSS2.0  ---> Contract-Connect ---> Security-Center.
                // Schließt der Benutzer seine Aktion im Security-Center ab, soll
                // er zurück zum Contract-Connect geleitet werden. Dabei darf die
                // Information woher der Benutzer ursprünglich kam (im Beispiel das
                // CSS 2.0) nicht verloren gehen.
                //
                // Kommt der Benutzer wie im Beispiel über das CSS2.0 zu
                // contract-connect, so lautet seine Adresse in etwa:
                //   https://auth-connect.ewe.de/?redirect_uri=https://service-portal.ewe.de/
                //
                // Klickt dieser Benutzer nun auf "Passwort ändern" soll er zum
                // auth-service (security-center) weitergeleitet werden.
                //   https://auth-service.ewe.de/changePassword
                //
                // Zusätzlich wird aber die Rücksprungadresse weitergegeben, so dass
                // der Benutzer nach dem Ändern des Passworts wieder zur Quelle
                // zurückkehren kann. Im Beispiel ergibt sich also die URL:
                //   https://auth-service.ewe.de/changePassword
                //   ?redirect_uri=https://service-portal.ewe.de/
                //   &back_uri=https://auth-connect.ewe.de/redirect_uri=https://service-portal.ewe.de/
                //
                // Die übergebene Back-URI enthält also selbst eine Redirect-URI.
                //
                // Im Beispiel wird der Benutzer also zur Passwort-Ändern-Seite
                // weitergeleitet, welche als Rücksprungadresse die
                // Contract-Connect-Seite enthält. Diese enthält wiederrum das
                // CSS2.0 als Rücksprungadresse.
                back_uri: window.location.href,
                logout_uri: logoutLink || undefined,
              })}`
            }
            contractConnectUri={window.location.href /* stay where user is */}
            logoutUri={`${process.env.REACT_APP_AUTH_PORTAL}/logout?client_id=${process.env.REACT_APP_USER_POOL_CLIENT_ID}&logout_uri=${logoutLink}`}
          />

          <Router>
            <MobileMenu
              onClose={() => setMobileMenuIsOpen(false)}
              isOpen={mobileMenuIsOpen}
              items={
                accountListHasEntries
                  ? [
                      {
                        url: cssLink,
                        label: "Zur Vertragsübersicht",
                        isExternal: true,
                      },
                    ]
                  : [
                      {
                        url: `/addaccountlink?${redirectSearchParams}`,
                        label: "Kundennummer hinzufügen",
                        isExternal: false,
                      },
                    ]
              }
            ></MobileMenu>
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <LinkedAccountsList
                    // The key ensures that the component rerenders when a
                    // new user is authenticated.
                    // This in turn ensures that the data in the contract list
                    // gets refreshed as the queries will rerun (since there is
                    // no caching).
                    key={authData.attributes.sub}
                    accountListHasEntries={accountListHasEntries}
                    onLoaded={setAccountListHasEntries}
                    cssLink={cssLink}
                    redirectSearchParams={redirectSearchParams}
                  />
                )}
              />
              <Route
                exact
                path="/addaccountlink"
                render={props => (
                  <AddAccountLink
                    onAddAccountLink={onAddAccountLink}
                    onComponentDidMount={() => setMobileMenuIsOpen(false)}
                    redirectSearchParams={redirectSearchParams}
                  />
                )}
              />
              <Route
                path="/deleteaccountlink/:typ/:kundennummer"
                render={props => (
                  <DeleteAccountLink
                    {...props}
                    onDeleteAccountLinkPowercloud={
                      onDeleteAccountLinkPowercloud
                    }
                    onDeleteAccountLinkEasyplus={onDeleteAccountLinkEasyplus}
                    redirectSearchParams={redirectSearchParams}
                  />
                )}
              />
            </Switch>
          </Router>
        </ContentWrapper>
        <FooterContainer>
          <Footer cssLink={cssLink} />
        </FooterContainer>
      </PageContainer>
    </ThemeProvider>
  );
}

export default App;
