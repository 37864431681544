"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ScrollToTop;

var _react = require("react");

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var scrollToTop = function scrollToTop(behavior) {
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: behavior
    });
  } catch (error) {
    document.scrollTo(0, 0);
  }
};

function ScrollToTop(_ref) {
  var _ref$behavior = _ref.behavior,
      behavior = _ref$behavior === void 0 ? "auto" : _ref$behavior;
  (0, _react.useEffect)(function () {
    if (!window) {
      return;
    }

    scrollToTop(behavior);
  }, [behavior]);
  return null;
}

ScrollToTop.propTypes = {
  behavior: _propTypes["default"].oneOf(["smooth", "auto"])
};