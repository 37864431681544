"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var DialogBoxFooter = (0, _styledBase["default"])("div", {
  target: "e1e4pmoq0",
  label: "DialogBoxFooter"
})(process.env.NODE_ENV === "production" ? {
  name: "ha9isz",
  styles: "@media (min-width:600px){}font-size:20px;line-height:1.1;padding-bottom:20px;margin-bottom:24px;font-weight:500;display:block;text-align:left;}"
} : {
  name: "ha9isz",
  styles: "@media (min-width:600px){}font-size:20px;line-height:1.1;padding-bottom:20px;margin-bottom:24px;font-weight:500;display:block;text-align:left;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2RpYWxvZy1ib3gtZm9vdGVyLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVrQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9kaWFsb2ctYm94LWZvb3Rlci5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xuXG5jb25zdCBEaWFsb2dCb3hGb290ZXIgPSBzdHlsZWQuZGl2YFxuICBAbWVkaWEgKG1pbi13aWR0aDogNjAwcHgpIHtcbiAgfVxuICBmb250LXNpemU6IDIwcHg7XG4gIGxpbmUtaGVpZ2h0OiAxLjE7XG4gIHBhZGRpbmctYm90dG9tOiAyMHB4O1xuICBtYXJnaW4tYm90dG9tOiAyNHB4O1xuICBmb250LXdlaWdodDogNTAwO1xuICBkaXNwbGF5OiBibG9jaztcbiAgdGV4dC1hbGlnbjogbGVmdDtcbn1cbmA7XG5cbmV4cG9ydCBkZWZhdWx0IERpYWxvZ0JveEZvb3RlcjtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = DialogBoxFooter;
exports["default"] = _default;