"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var HeadlineContent = (0, _styledBase["default"])("div", {
  target: "e1otpgql0",
  label: "HeadlineContent"
})(process.env.NODE_ENV === "production" ? {
  name: "c40g5n",
  styles: "@media (min-width:768px){font-size:28px;line-height:1.14;}font-size:20px;line-height:1.6;box-sizing:border-box;margin:0;padding:0;border:0;font:inherit;vertical-align:baseline;display:block;"
} : {
  name: "c40g5n",
  styles: "@media (min-width:768px){font-size:28px;line-height:1.14;}font-size:20px;line-height:1.6;box-sizing:border-box;margin:0;padding:0;border:0;font:inherit;vertical-align:baseline;display:block;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2hlYWRsaW5lLWNvbnRlbnQuanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRWtDIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2hlYWRsaW5lLWNvbnRlbnQuanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcblxuY29uc3QgSGVhZGxpbmVDb250ZW50ID0gc3R5bGVkLmRpdmBcbiAgQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XG4gICAgZm9udC1zaXplOiAyOHB4O1xuICAgIGxpbmUtaGVpZ2h0OiAxLjE0O1xuICB9XG4gIGZvbnQtc2l6ZTogMjBweDtcbiAgbGluZS1oZWlnaHQ6IDEuNjtcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgbWFyZ2luOiAwO1xuICBwYWRkaW5nOiAwO1xuICBib3JkZXI6IDA7XG4gIGZvbnQ6IGluaGVyaXQ7XG4gIHZlcnRpY2FsLWFsaWduOiBiYXNlbGluZTtcbiAgZGlzcGxheTogYmxvY2s7XG5gO1xuXG5leHBvcnQgZGVmYXVsdCBIZWFkbGluZUNvbnRlbnQ7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = HeadlineContent;
exports["default"] = _default;