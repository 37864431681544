"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var Subline = (0, _styledBase["default"])("span", {
  target: "eug8nms0",
  label: "Subline"
})(process.env.NODE_ENV === "production" ? {
  name: "hogqdq",
  styles: "font-size:13px;line-height:1.69;color:#333333;"
} : {
  name: "hogqdq",
  styles: "font-size:13px;line-height:1.69;color:#333333;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3N1YmxpbmUuanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRTJCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL3N1YmxpbmUuanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcblxuY29uc3QgU3VibGluZSA9IHN0eWxlZC5zcGFuYFxuICBmb250LXNpemU6IDEzcHg7XG4gIGxpbmUtaGVpZ2h0OiAxLjY5O1xuICBjb2xvcjogIzMzMzMzMztcbmA7XG5cbmV4cG9ydCBkZWZhdWx0IFN1YmxpbmU7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
var _default = Subline;
exports["default"] = _default;