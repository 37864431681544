import React from "react";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import { palette } from "@ewe-it/ewe-design-react";

const Loader = css`
  display: block;
  margin: 0 auto;
  border-color: white;
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const Loading = loading => {
  return (
    <div className="sweet-loading">
      <BounceLoader
        css={Loader}
        sizeUnit={"px"}
        size={60}
        color={palette.secondaryBg}
        loading={loading.loading}
      />
    </div>
  );
};
